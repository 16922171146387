/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 *
 * Google CDN, Latest jQuery
 * To use the default WordPress version of jQuery, go to lib/config.php and
 * remove or comment out: add_theme_support('jquery-cdn');
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        // load your google fonts here
        WebFontConfig = {
          google: { families: [ 'Montserrat:400,700:latin' ] }
        };
        (function() {
          var wf = document.createElement('script');
          wf.src = '//ajax.googleapis.com/ajax/libs/webfont/1/webfont.js';
          wf.type = 'text/javascript';
          wf.async = 'true';
          var s = document.getElementsByTagName('script')[0];
          s.parentNode.insertBefore(wf, s);
        })();

        // variables globales
        // ------------------
        var gridFloatBreakpoint = 992;  // less media queries : @grid-float-breakpoint (992px)
        var scrollTop_value = 0;

        // navbar-collapse auto height (when openned)
        $(window).load(function() {
          var window_height = $(window).height();
           // console.log('window height : ' + window_height + 'px');
           // 992 is the breakpoint
           if ($(window).width() < gridFloatBreakpoint) {
              scrollTop_value = 0; // needed for the navbar shrink
              // $('#main-menu-collapse.navbar-collapse').css('min-height', window_height+'px');
           }
           else {
              scrollTop_value = 60;
              $('#main-menu-collapse.navbar-collapse').css('min-height', '100%');
           }
        });
        
        // navbar shrink on scroll
        $(window).scroll(function() {
          if ($(document).scrollTop() >= scrollTop_value) {
            $('header').addClass('shrink');
          } else {
            $('header').removeClass('shrink');
          }
        });

        // navbar search in dropdown
        $('#open-dropdown-search').click(function () {
          setTimeout(function(){$('#nav-search-input').focus();},0);
        });
        $('#open-dropdown-search input[type=search]').click(function(e){
            e.stopPropagation();
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        /*
        $('#team-selector li a').each(function(){
          // if the pathname of the href references the same page
              // only keep the hash, i.e. do not keep the pathname
            
             //$(this).smoothScroll();
             
             //$(this).removeClass('active');
             //$(this).parent().toggleClass('active');
        });
        */
       
        // smoothscroll
        $('a.back-to-team').smoothScroll();

        $('a.member-get-in-touch').on('click', function() {
            $.smoothScroll({
              //scrollElement: $('div.scrollme'),
              scrollTarget: '#contact'
            });
            return false;
          });

 
        $('#team-selector li a, #team .team-thumb a').click(function(e) {
          
          e.preventDefault();

            var $target = $($(this).attr('href')),
            $other = $target.siblings('.active-member'),
            animIn = function () {
              $target.addClass('active-member').show();
            };
              
            if (!$target.hasClass('active-member') && $other.length > 0) {
              $other.each(function(index, self) {
                var $this = $(this);
                $this.removeClass('active-member');
                animIn();
              });
            } 
            else if (!$target.hasClass('active-member')) {
              animIn();
            }

            $('#team-selector li a').removeClass('active');
            $('#team .team-thumb').removeClass('active');
            $('#team .team-thumb a').removeClass('active');

            $(this).addClass('active');
            var href = $(this).attr('href');
            $('#team-selector li a[href$="'+href+'"]').addClass('active');
            var activeThumb = $('#team .team-thumb a[href$="'+href+'"]');
            $(activeThumb).addClass('active');
            $(activeThumb).closest('div.team-thumb').addClass('active');

            var $desti = $target.offset().top;
            $('html, body').animate({ scrollTop: $desti-15}, 'slow');
            return false;
        });  


        // scrollspy for one page nav
        // source: http://jsfiddle.net/mekwall/up4nu/

        // Cache selectors
          var lastId,
              topMenu = $("#menu-international"),
              topMenuHeight = topMenu.outerHeight()+15,
              // All list items
              menuItems = topMenu.find("a"),
              // Anchors corresponding to menu items
              scrollItems = menuItems.map(function(){
                var item = $($(this).attr("href"));
                if (item.length) { return item; }
              });

          // Bind click handler to menu items
          // so we can get a fancy scroll animation
          menuItems.click(function(e){
            var href = $(this).attr("href"),
                offsetTop = href === "#" ? 0 : $(href).offset().top-topMenuHeight+1;
            $('html, body').stop().animate({ 
                scrollTop: offsetTop
            }, 500);
            e.preventDefault();
          });

          // Bind to scroll
          $(window).scroll(function(){
             // Get container scroll position
             var fromTop = $(this).scrollTop()+topMenuHeight;
             
             // Get id of current scroll item
             var cur = scrollItems.map(function(){
               if ($(this).offset().top < fromTop) {
                 return this;
               }  
             });
             // Get the id of the current element
             cur = cur[cur.length-1];
             var id = cur && cur.length ? cur[0].id : "";
             
             if (lastId !== id) {
                 lastId = id;
                 // Set/remove active class
                 menuItems
                   .parent().removeClass("active")
                   .end().filter("[href='#"+id+"']").parent().addClass("active");
             }                   
          });


          /*
          $(window).scroll(function() {
              if ($(window).scrollTop() < 120) {
                  $('#menu-international li a').each(function() {
                      $(this).parent().removeClass('active');
                      //alert($(this).attr("value"));
                  });
                  $('#menu-international li.menu-presentation').addClass('active');
              }
          });

         $(function() {
            $('#menu-international li a').each(function() {
              $(this).smoothScroll();
              $(this).click(function() {
                $('#menu-international li.menu-presentation').removeClass('active');
                $(this).parent().toggleClass('active');
                //alert($(this).attr("value"));
              });
            });
          });
        */

        // international france
        $('#intro-one .expander-trigger').click(function(){
          $(this).toggleClass("expander-hidden");
          $('#intro-one .expander-trigger .readmore').toggleClass("hidden");
          $('#intro-one .expander-trigger .unexpand').toggleClass("hidden");
          $('#intro-one .expander-content').toggleClass("expand-content-hidden");
        });

        // international col2
        $('#intro-two .expander-trigger').click(function(){
          $(this).toggleClass("expander-hidden");
          $('#intro-two .expander-trigger .readmore').toggleClass("hidden");
          $('#intro-two .expander-trigger .unexpand').toggleClass("hidden");
          $('#intro-two .expander-content').toggleClass("expand-content-hidden");
        });

        // international relevant experience bloc
        $('#experience .expander-trigger').click(function(){
          $(this).toggleClass("expander-hidden");
          $('#experience .expander-trigger .readmore').toggleClass("hidden");
          $('#experience .expander-trigger .unexpand').toggleClass("hidden");
          $('#experience .expander-content').toggleClass("expand-content-hidden");
        });
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

        // Carousel script 
        $('.carousel').carousel({
          interval: 4000
        });

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Equipes page, grid filter, with bourbon Saas library
    'equipes': {
      init: function() {
        // JavaScript to be fired on the "Equipes" page
        
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },

    // Contact page
    'contact': {
      init: function() {
        // JavaScript to be fired on the contact page

        //Google maps multiple iframe
        $(function(){

          // load map
          $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
          var that=$($(e.target).attr('href')).find('.map');
          if(!that.find('iframe').length){ that.append($('<iframe/>',{src:that.data('map')}).css({height:'400px',width:'100%',border:'none'}));
          }
        }).first().trigger('shown.bs.tab');

        });


       $(function(){

         // getUrl parameter
            var getUrlParameter = function getUrlParameter(sParam) {

            var sPageURL = decodeURIComponent(window.location.search.substring(1)),
                sURLVariables = sPageURL.split('&'),
                sParameterName,
                i;

            for (i = 0; i < sURLVariables.length; i++) {
                sParameterName = sURLVariables[i].split('=');

                if (sParameterName[0] === sParam) {
                    return sParameterName[1] === undefined ? true : sParameterName[1];
                }
            }

          };

           //open a specific map tab
           var map_ville = 'nantes'; // string par defaut

          if (getUrlParameter('ville')) { 
              map_ville = getUrlParameter('ville');
              $('#map-tabs a[href="#'+map_ville+'"]').tab('show'); // Select tab by name
          }
          // end getUrl stuff
        });
       

      }
    } //end contact js


  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
